import { projectName } from '../micro-frontend.config'

let importMap: any

async function fetchImportMap() {
  if (window.location.hostname.includes('localhost')) {
    const res = await fetch('https://partner-dev.spreetail.com/configs/importmap.json')
    importMap = {
      ...(await res.json()),
      ...{
        portal_root_config: 'http://localhost:9000'
      }
    }
  } else {
    const res = await fetch('/configs/importmap.json')
    importMap = await res.json()
  }
}

;(window as any).getRemoteUrl = (remote: any) => {
  const overridesJSON = localStorage.getItem('IMPORT_MAP_OVERRIDES')
  const overrides = overridesJSON ? JSON.parse(overridesJSON) : { remotes: {} }
  if (overrides.remotes[remote]) {
    return overrides.remotes[remote]
  }
  return importMap.remotes[remote]
}

function setLogRocket() {
  const params = new URLSearchParams(window.location.search)
  if (params.get('disableLogRocket') === 'true') {
    localStorage.setItem('disableLogRocket', 'true')
  }
}

async function bootstrap() {
  setLogRocket()
  await fetchImportMap()
  return import('./bootstrap')
}

/* eslint-disable no-console */
bootstrap()
  .then(() => console.debug(`${projectName} bootstrapped`))
  .catch((err) => console.error(`Unable to bootstrap ${projectName}`, err))
/* eslint-enable no-console */
