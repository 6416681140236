// eslint-disable-next-line no-undef
module.exports = {
  projectName: 'portal_root_config',
  port: 9000,
  remotes: [
    // Add app remotes here
    'portal_root_config',
    'portal_navbar',
    'portal_dashboard',
    'invoices',
    'user',
    'account_settings',
    'faqs',
    'user_management',
    'sales',
    'login',
    'vendor_contacts',
    'notification_center',
    'catalog_management',
    'help_center',
    'order_management',
    'product_content',
    'prospective_resource'
  ],
  exposes: {
    './i18n': './src/utils/i18n'
  }
}
